import { NOT_FOUND } from 'redux-first-router'

export const FETCH_PAGE_ARTICLES_CATEGORY = 'FETCH_PAGE_ARTICLES_CATEGORY'
export const FETCH_PAGE_ARTICLES_CATEGORY_START =
    'FETCH_PAGE_ARTICLES_CATEGORY_START'
export const FETCH_PAGE_ARTICLE = 'FETCH_PAGE_ARTICLE'
export const FETCH_PAGE_ARTICLE_START = 'FETCH_PAGE_ARTICLE_START'
export const FETCH_PAGE_TRANSITION_EVENT = 'FETCH_PAGE_TRANSITION_EVENT'

export const TOGGLE_ACCOUNT_MENU = 'TOGGLE_ACCOUNT_MENU'

export interface PagesActionType {
    type: string
    payload?: unknown
}

export const setArticlesCategory = (
    articlesCategory: string
): PagesActionType => ({
    type: FETCH_PAGE_ARTICLES_CATEGORY,
    payload: { articlesCategory },
})
export const setArticlesCategoryStarted = (): PagesActionType => ({
    type: FETCH_PAGE_ARTICLES_CATEGORY_START,
})
export const setArticle = (article: string): PagesActionType => ({
    type: FETCH_PAGE_ARTICLE,
    payload: { article },
})
export const setArticleStarted = (): PagesActionType => ({
    type: FETCH_PAGE_ARTICLE_START,
})

export function setPageTransitionEvent(): PagesActionType {
    return {
        type: FETCH_PAGE_TRANSITION_EVENT,
    }
}

export const setError = (): PagesActionType => ({ type: 'ERROR' })
export const setNotFound = (): PagesActionType => ({ type: NOT_FOUND })
export const redirect = (
    page?: string,
    subpage?: string,
    id?: string,
    lang?: string
): PagesActionType => ({
    type: 'PAGE',
    payload: { lang, page, subpage, id },
})
