
import PageLocationType from "models/page-location-type"
import { GlobalState } from "types"
import { LocationState } from "types/redux/GlobalState"

export const getLocation = (state: GlobalState): LocationState => state && state.location
export const getPathname = (state: GlobalState): string => state && getLocation(state).pathname
export const getLocationPayload = (state: GlobalState): PageLocationType =>
    getLocation(state) && getLocation(state).payload
export const getPage = (state: GlobalState): string =>
    getLocationPayload(state) && getLocationPayload(state).page
export const getSubpage = (state: GlobalState): string =>
    getLocationPayload(state) && getLocationPayload(state).subpage
export const getId = (state: GlobalState): string =>
    getLocationPayload(state) && getLocationPayload(state).id
/** @deprecated This property is deprecated and will be removed in future versions. */
export const getSubId = (state: GlobalState): string =>
    getLocationPayload(state) && getLocationPayload(state).subid
export const getQuery = (state: GlobalState): { [key: string]: string } =>
    getLocation(state) && getLocation(state).query
