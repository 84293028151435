import axios from 'axios'
import _find from 'lodash/find'
import Logger from 'utils/logging'
import Slugger from 'github-slugger'
import getConfig from 'config/web'
import { AccountInfo, OmegaStatus } from 'types/account'
import SectionType from 'models/types/ts/sectionType'

const { sectionsApiUrl, articlesApiUrl } = getConfig()

export function slugifySections(
    sections: Array<SectionType>
): Array<SectionType> {
    const slugger = new Slugger()
    return sections.map((item) => {
        const slug = slugger.slug(item.headline)
        return {
            ...item,
            slug,
        }
    })
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export function findSectionByIdentifier(
    sections: Array<SectionType>,
    identifier: string | string[]
): SectionType {
    return _find(
        sections,
        (section) => Array.isArray(identifier) ? identifier.includes(section.identifier) : section && section.identifier === identifier
    )
}

// TODO: Temporary hack to display EN content when passing JA into the articles API
function returnEnDefaultlang(language: string): string {
    // arrey of languages that our articles api does support
    const { supportedLanguagesInArticlesApi } = getConfig()

    // if language is not supported, return en
    if (!supportedLanguagesInArticlesApi.includes(language)) {
        return 'en'
    }
    return language
}

// TODO Remove all references to fetchSection, the last real usage of it was removed when we get frontPage hero banner with graphql
export async function fetchSection(id: string, language: string) {
    // Old code, keeping here for revert if needed
    // const { data } = await axios.get(`${SECTIONS_URL}/${id}?lang=${language}`)

    // return data[id]

    const url = `${sectionsApiUrl}/${id}?lang=${language}`

    return axios
        .get(url)

        .then((response) => {
            // Success 🎉
            if (response?.data) return response.data[id]
        })
        .catch((error) => {
            Logger.captureNetworkException(error, url, 'api.fetchSection')
        })
}

/**
 * TODO, code can be improved, missing .then and .catch and logging and just not proper data handling
 * @param subpage The slug of the article to get
 * @param language The language to get the article in
 * @returns Returns an article
 */
export async function fetchArticle(subpage: string, language: string) {
    const { data } = await axios.get(
        `${articlesApiUrl}/${subpage}?lang=${returnEnDefaultlang(language)}`
    )
    if (!data) {
        return null
    }

    return data
}
