import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import Loading from 'layouts/loading/PageLoading'
// import { LaunchDarklyProvider } from 'utils/launchDarkly/LaunchDarklyProvider'
import { useHasMounted } from 'hooks/client-only'

const LoadableComponent = React.lazy(() =>
    import(/* webpackChunkName: "page-signup-verify" */ './SignupVerify')
)

export default function LoadableSignupVerify(props) {
    const hasMounted = useHasMounted()

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            {hasMounted && (
                <React.Suspense fallback={<Loading />}>
                    {/* <LaunchDarklyProvider> */}
                    <LoadableComponent {...props} />
                    {/* </LaunchDarklyProvider> */}
                </React.Suspense>
            )}
        </>
    )
}
