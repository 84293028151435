import React from 'react'
import values from 'config/values'
import LanguageType from 'models/language-type'
import { Content } from 'layouts'
import { Footer, SEO } from 'features'
import Navigation from 'features/navigation'
import TwitchStreamContainer from 'features/twitch-stream/TwitchStreamContainer'
import ErrorBoundaryOuter from 'features/error-boundary/ErrorBoundaryOuter'
import { getPage } from 'lib/location/selectors'
import { useSelector } from 'react-redux'
import { useHasMounted } from 'hooks/client-only'
import { GlobalState } from 'types'

/* eslint-disable react/jsx-props-no-spreading */

interface Props {
    page: React.ReactNode
    lang: LanguageType
    hideMenuBorder: boolean
    showLogin: boolean
    hideCTA: boolean
    showTwitch: boolean
}

const DefaultPageLayout = ({
    page,
    lang,
    hideMenuBorder,
    hideCTA,
    showTwitch,
}: Props): JSX.Element => {
    const currentPage = useSelector((state: GlobalState) => getPage(state))
    const isFrontpage = currentPage === undefined

    const hasMounted = useHasMounted()

    return (
        <div className={lang}>
            <SEO {...values.seo} hideSitename />
            {showTwitch && <TwitchStreamContainer />}
            <Navigation
                hideBorder={hideMenuBorder}
                hideCTA={hideCTA}
                frontpageTheme={isFrontpage}
            />
            <Content>
                <ErrorBoundaryOuter>{page}</ErrorBoundaryOuter>
            </Content>
            <Footer frontpageTheme={isFrontpage} />
        </div>
    )
}

export default DefaultPageLayout
