import { redirect as rfrRedirect } from 'redux-first-router'
import sitemap from 'config/sitemap'
import { isSingle } from 'lib/pages/selectors'
import { SitemapPageModel } from 'models/sitemap-model'
import { Dispatch } from 'redux'
import { GlobalState } from 'types'
import LanguageType from 'models/language-type'
import {
    redirect,
    setNotFound,
    setPageTransitionEvent,
    PagesActionType,
} from './actions'
import { languageChange } from '../language/actions'
import { hideNavigation } from '../menu/actions'
import { getLanguage, getLanguages } from '../language/selectors'

function getActivePage(page, subpage): SitemapPageModel {
    // If page is undefined in here then we are looking for home.
    const pageName = !page ? 'home' : page
    const activePage = sitemap[pageName]

    if (!activePage) {
        return undefined
    }

    // If subpage is set and main page has children then use that as activePage.
    // try {
    if (subpage && activePage.children) {
        // ignore next line
        // @ts-ignore This is old code that is not used anymore, adding ignore to not change the code, but it has TS errors also
        return activePage.children
    }

    return activePage
}

// Can be removed when these pages are in Contentful and configured like others.
// TODO
// CODE_IMPROVEMENTS: This function and usage is most likely not needed anymore, these last pages are in Contentful now.
const singleLegacyPages = ['discovery', 'monument']
function isSingleLegacyPage(page): boolean {
    return singleLegacyPages.includes(page)
}

// Page actions is like action we had in controllers in the MVC pattern,
// except this one is shared for everything.
export default async function pageActions(
    dispatch: Dispatch<PagesActionType>,
    getState: () => GlobalState
): Promise<PagesActionType> {
    const {
        payload: { lang, page, subpage, id },
    } = getState().location

    // Language
    let language = getLanguage(getState())
    let actionLanguage = lang as LanguageType
    const languages = getLanguages()
    if (lang == null || languages.indexOf(lang) === -1) {
        // Fallback to english
        actionLanguage = 'en'
    }
    if (actionLanguage !== language) {
        dispatch(languageChange(actionLanguage))
        language = actionLanguage
    }

    // There is /en/ in the url, remove it
    if (lang === 'en') {
        return dispatch(rfrRedirect(redirect(page, subpage, id)))
    }

    const activePage = getActivePage(page, subpage)

    // Return NOT FOUND if sitemap does not contain page.
    if (!activePage) {
        console.log('dispatch not found in actions.js') // Todo remove console.log maybe?
        return dispatch(setNotFound())
    }

    // CODE_IMPROVEMENTS
    // NOT SURE THIS IS USED ANYMORE
    // Disabled pages
    if (activePage.disabled) {
        return dispatch(setNotFound())
    }

    // if the page is a single page with no subpages allowed, then remove all other parts of url, example /download
    // TODO can be removed when legacy pages are in Contentful, doing redirect below based on page.config.pageType value
    if (isSingleLegacyPage(page) && subpage) {
        return dispatch(rfrRedirect(redirect(page, null, null, lang)))
    }

    // There is /home/ in the url, remove it
    // CODE_IMPROVEMENTS: What? Is this a real thing, old stuff, but might be really important.
    if (page === 'home') {
        if (actionLanguage === 'en') {
            return dispatch(rfrRedirect(redirect()))
        }
        return dispatch(
            rfrRedirect(
                redirect(undefined, undefined, undefined, actionLanguage)
            )
        )
    }

    // Redirects
    if (activePage.redirect) {
        const action = activePage.redirect
        // @ts-ignore Ignore for now, this should be deprecated at some point
        action.payload.lang = language
        // @ts-ignore Ignore for now, this should be deprecated at some point
        return dispatch(rfrRedirect(action))
    }

    // Show/Hide the navigation
    dispatch(hideNavigation(activePage.hideMenu))

    if (activePage.populate) {
        // Has populate function so calling it.
        const result = await activePage.populate(dispatch, getState, language)

        // Added to make sure that pages that are single should not have sub-subpages so redirecting to the base subpage.
        // ignore next line
        // @ts-ignore Ignore for now, not sure what this is doing but keeping as is
        if (result && result.payload && result.payload.page) {
            // @ts-ignore Ignore for now, not sure what this is doing but keeping as is
            if (isSingle(result.payload.page) && subpage) {
                return dispatch(rfrRedirect(redirect(page, null, null, lang)))
            }
        }

        // dispatch(setGoogleOptimizeEvent())
    } else {
        // dispatch(setGoogleOptimizeEvent())
    }

    // CODE_IMPROVEMENTS: This is old code, not sure if it is used anymore, but it runs on page changes.
    switch (activePage.id) {
        case 'store':
            break
        case 'callback':
            break
        case 'login':
            break
        default:
            dispatch(setPageTransitionEvent())
            break
    }
}
