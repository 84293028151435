import React, { useEffect, useState } from 'react'

import { Section, Container } from 'layouts'
import SectionType from 'models/types/ts/sectionType'
import { useHasMounted } from 'hooks/client-only'
import { HeadingSmall } from 'layouts/typography'
import DownloadButton from '../../../../features/DownloadButton'
import SystemRequirements from './SysReq'
import style from './Download.module.scss'

interface Props {
    title: string
    body: string
    sections: Array<SectionType>
    hasContent: boolean
}

const Download = ({
    title,
    body,
    sections,
    hasContent,
}: Props): JSX.Element => {
    const hasMounted = useHasMounted()

    // const [hasVersions, setHasVersions] = useState(false)

    // const dispatch = useDispatch()
    // // Making sure we have versions in state.
    // useEffect(() => {
    //     if (!hasVersions) {
    //         // Only use dispatch once, when using useSelector to later this causes an endless loop.
    //         // @ts-ignore Have to call dispatch here
    //         dispatch(fetchVersionIfNeeded)
    //         setHasVersions(true)
    //     }
    // })

    return (
        <div className={style.download}>
            <Section hasContent={hasContent}>
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <HeadingSmall>{title}</HeadingSmall>

                        <div
                            style={{
                                maxWidth: '400px',
                                marginTop: '40px',
                                textAlign: 'center',
                                margin: '0 auto',
                            }}
                        >
                            {hasMounted && <DownloadButton />}
                            {/* // eslint-disable-next-line react/jsx-pascal-case */}
                            {/* {hasMounted && <DownloadButtonObsolete />} */}
                        </div>
                    </div>
                    {/* <OldLauncherDownloadContainer /> */}
                    {/* <BetaLauncherDownload /> */}
                    <SystemRequirements sections={sections} />
                </Container>
            </Section>
        </div>
    )
}

export default Download
