import React, { useContext } from 'react'
import cx from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { Translate, TranslateFunction } from 'utils/localize'
import ReactTooltip from 'react-tooltip'
import flags from 'config/flags'
import { login } from 'packages/authentication/lib/actions'
import { Icon } from 'layouts'
import AccountIcon, { IconAccountTypeEnum } from 'layouts/svgIcon/account'
import getConfig from 'config/web'
import {
    isLoggedIn,
    getLoggedInUser,
    getLoggedInJwt,
} from 'packages/authentication/lib/selectors'
import { getLanguage } from 'selectors'
import PlexBalance from 'features/plex-balance'
import Button from 'layouts/button'
import SpecialOfferCount from 'features/navigation/components/specialOfferCount'
import { UserContext } from 'utils/context/UserContext'
import { GlobalState } from 'types'
import Item from './components/item'
import s from './AccountManagementMenu.module.scss'
import itemStyle from './components/item/Item.module.scss'

interface NavItemProps {
    translationId: string
    url: string
    external?: boolean
    icon: React.ReactNode
}
interface Props {
    currentPage: string
    className?: string
    fpThemeColor?: boolean
}

const { secureUrl, secureAccountManagementUrl, authentication } = getConfig()

const items: Array<NavItemProps> = [
    {
        translationId: 'navigation.account.info',
        url: secureAccountManagementUrl,
        external: true,
        icon: <AccountIcon icon={IconAccountTypeEnum.AccountManagement} />,
    },
    {
        translationId: 'navigation.recruit',
        url: 'recruit',
        icon: <AccountIcon icon={IconAccountTypeEnum.Recruit} />,
    },
    {
        translationId: 'navigation.account.logout',
        url: 'logout',
        icon: <AccountIcon icon={IconAccountTypeEnum.LogOut} />,
    },
]

const AccountManagementMenu = ({
    className,
    currentPage,
    fpThemeColor,
}: Props): JSX.Element => {
    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const userToken = useSelector((state: GlobalState) => getLoggedInJwt(state))
    const username = useSelector((state: GlobalState) => getLoggedInUser(state))
    const language = useSelector((state: GlobalState) => getLanguage(state))
    const { specialOfferCount } = useContext(UserContext)
    const dispatch = useDispatch()

    const plexUrl = `${secureUrl}/store/plex`
    const secureUrlLangQuery =
        language === 'en' ? plexUrl : `${plexUrl}?lan=${language}`

    const supportLangParam = language === 'en' ? 'en-us' : `${language}`
    const tooltipLink = `https://support.eveonline.com/hc/${supportLangParam}/articles/115003168285-PLEX-Vault`

    function getOfferText(
        translate: TranslateFunction,
        offerCount: number
    ): string {
        if (offerCount === 1) {
            return translate('navigation.account.offerAvailable')
                .toString()
                .replace('##COUNT##', offerCount.toString())
        }
        if (offerCount > 1) {
            return translate('navigation.account.offerAvailablePlural')
                .toString()
                .replace('##COUNT##', offerCount.toString())
        }
    }

    return (
        <Translate>
            {({ translate }) => (
                <div
                    className={cx(
                        s.accountmenu,
                        {
                            [s.colorTheme]: flags.features.colorThemeEnabled,
                        },
                        className
                    )}
                >
                    {!loggedIn ? (
                        <a
                            onClick={() =>
                                dispatch(login(currentPage, authentication))
                            }
                            role="button"
                            aria-label="Login"
                            tabIndex={0}
                        >
                            <div
                                className={cx(s.selected, s.login, {
                                    [s.fpTheme]: fpThemeColor,
                                })}
                            >
                                <AccountIcon
                                    icon={IconAccountTypeEnum.AccountAvatar}
                                    className={s.userIcon}
                                    iconColor="#5ccbcb"
                                />
                                <span className={s.loginText}>
                                    {translate('navigation.account.login')}
                                </span>
                            </div>
                        </a>
                    ) : (
                        <>
                            <SpecialOfferCount />
                            <div className={s.selected}>
                                <AccountIcon
                                    icon={IconAccountTypeEnum.AccountAvatar}
                                    className={s.userIcon}
                                    iconColor="#5ccbcb"
                                />
                                <div className={s.userName}>{username}</div>
                                <span className={s.arrow}>
                                    <Icon
                                        name="chevron-down"
                                        className={s.arrow}
                                    />
                                </span>
                            </div>
                            <ul>
                                <div className={s.plex}>
                                    <PlexBalance />
                                    <div className={s.details}>
                                        <a
                                            href={tooltipLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-tip={translate(
                                                'navigation.plexDescription'
                                            )}
                                            className={s.detailsIcon}
                                        >
                                            <AccountIcon
                                                icon={
                                                    IconAccountTypeEnum.Details
                                                }
                                            />
                                        </a>
                                        <Button
                                            className={s.btn}
                                            path={secureUrlLangQuery}
                                            small
                                            theme="omega"
                                        >
                                            {translate('navigation.buyPlex')}
                                        </Button>
                                    </div>
                                </div>
                                {specialOfferCount > 0 && (
                                    <li className={itemStyle.item}>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${secureUrl}?token=${userToken}`}
                                        >
                                            <div className={itemStyle.icon}>
                                                <AccountIcon
                                                    icon={
                                                        IconAccountTypeEnum.Offer
                                                    }
                                                />
                                            </div>
                                            {getOfferText(
                                                translate,
                                                specialOfferCount
                                            )}
                                        </a>
                                    </li>
                                )}

                                {items.map(
                                    ({
                                        translationId,
                                        url,
                                        external,
                                        icon,
                                    }) => (
                                        <Item
                                            key={translationId}
                                            text={translationId}
                                            external={external}
                                            url={url}
                                            icon={icon}
                                            lang={language}
                                        />
                                    )
                                )}
                            </ul>
                        </>
                    )}
                    <ReactTooltip
                        place="bottom"
                        type="dark"
                        className={s.tooltip}
                    />
                </div>
            )}
        </Translate>
    )
}

AccountManagementMenu.defaultProps = {
    className: '',
    fpThemeColor: '',
}

export default AccountManagementMenu
