import {
    FETCH_PAGE_ARTICLES_CATEGORY,
    FETCH_PAGE_ARTICLES_CATEGORY_START,
    FETCH_PAGE_ARTICLE,
    FETCH_PAGE_ARTICLE_START,
} from './actions'

import { LANGUAGE_CHANGE } from '../language/actions'

const INITIAL_STATE = {
    home: {
        isFetching: false,
        hasContent: false,
    },
}

export interface PagesStateAction {
    type: string
    payload?: {
        article: unknown
    }
}

function pages(state = INITIAL_STATE, action: PagesStateAction): unknown {
    switch (action.type) {
        case LANGUAGE_CHANGE: {
            return { home: { hasContent: false } }
        }

        case FETCH_PAGE_ARTICLE_START: {
            return {
                ...state,
                hasContent: false,
                isFetching: false,
                article: undefined,
            }
        }
        case FETCH_PAGE_ARTICLE: {
            return {
                ...state,
                article: action.payload.article,
            }
        }
        case FETCH_PAGE_ARTICLES_CATEGORY_START: {
            return {
                ...state,
                articlesCategory: undefined,
            }
        }
        case FETCH_PAGE_ARTICLES_CATEGORY: {
            return {
                ...state,
                articlesCategory: action.payload,
            }
        }
        default:
            return state
    }
}

export default pages
