import React from 'react'
import SectionType from 'models/types/ts/sectionType'
import classNames from 'classnames'
import AnimatedText from 'layouts/animated-text/AnimatedText2'
import BackgroundImage from 'features/background-image'
import ReactMarkdown from 'utils/markdown'
import { HeadingMedium } from 'layouts/typography'
import { Container } from 'layouts'
import { Button, Target } from '@ccpgames/eve-web/components'
import { FeatureVideo } from 'features'
import s from './mosaic.module.scss'
import MosaicSearch from './MosaicSearch'

interface Props {
    section: SectionType
}

const Mosaic = ({ section }: Props): JSX.Element => {
    const cx = classNames.bind(s)

    return (
        <BackgroundImage className={s.mosaic} url={section.imageFile.url}>
            {section && (
                <Container xlWide className={s.container}>
                    <div className={s.content}>
                        <div className={s.copy}>
                            <HeadingMedium as="h2" className={s.title}>
                                <AnimatedText>{section.headline}</AnimatedText>
                            </HeadingMedium>
                            <MosaicSearch searchUrl={section.buttonUrl} />
                            <ReactMarkdown source={section.body} />
                        </div>
                        <Target color="#FFF" className={s.button}>
                            <Button
                                href={section.buttonUrl}
                                // className={style.btn}
                                backgroundColor="#FFF"
                                style={{ color: 'black', height: '60px' }}
                            >
                                {section.buttonText}
                            </Button>
                        </Target>
                    </div>
                    <div className={s.image}>
                        <FeatureVideo
                            image="https://images.ctfassets.net/7lhcm73ukv5p/2qjxsimr66130sPE7cn7nq/fafc1240eb40d767bb000459dbc3bd77/mosaic_05b__1_.jpg"
                            videoId="DweFEVVgSXk"
                            subTitle=""
                        />
                    </div>
                </Container>
            )}
        </BackgroundImage>
    )
}

export default Mosaic
