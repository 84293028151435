import React, { useRef } from 'react'
import { TextInput } from '@mantine/core'
import SearchIcon from './SearchIcon'

interface MosaicSearchProps {
    searchUrl: string
}

const MosaicSearch = ({ searchUrl }: MosaicSearchProps): JSX.Element => {
    const inputRef = useRef(null)

    function doSearch(event, url: string): void {
        event.preventDefault()
        if (inputRef) {
            console.log(inputRef.current.value)
            if (window && window.location) {
                const urlWithSearch = `${url}/?pilot=${inputRef.current.value}`
                window.location.href = urlWithSearch
            }
        }
    }

    return (
        <form onSubmit={(e) => doSearch(e, searchUrl)}>
            <TextInput
                ref={inputRef}
                placeholder="Search by pilot name"
                radius="xl"
                autoComplete="off"
                styles={{
                    input: {
                        fontSize: '18px',
                        fontFamily: 'Shentox, sans-serif',
                        height: '40px',
                        margin: '20px 0',
                        padding: '0 24px',
                        backgroundColor: '#D9D9D9',
                        border: 'none',
                        color: '#474746',
                        '&:focus': {
                            backgroundColor: '#D9D9D9',
                            '::placeholder': {
                                color: 'transparent',
                            },
                        },
                        '::placeholder': {
                            color: '#474746',
                        },
                    },
                }}
                rightSection={
                    <div
                        style={{
                            cursor: 'pointer',
                            marginRight: '12px',
                        }}
                        onClick={(e) => doSearch(e, searchUrl)}
                    >
                        <SearchIcon />
                    </div>
                }
                style={{
                    maxWidth: '450px',
                }}
            />
        </form>
    )
}

export default MosaicSearch
