import { WarzoneArcGoalsProvider } from '@ccpgames/eve-web/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import { getArcGoals } from './helpers/get-warzone-leaderboard'
// import { getWarzoneStatus } from './helpers/get-warzone-status'
// import { getWarzoneLeaderboard } from './helpers/get-warzone-leaderboard'

interface Props {
    children: React.ReactNode
    updateInterval?: number
}

export default function ArcGoalsProviders({
    children,
    updateInterval = 15000,
}: Props): JSX.Element {
    const language = useSelector((state: GlobalState) => state.language)

    return (
        <WarzoneArcGoalsProvider
            updateInterval={updateInterval}
            updateFunc={getArcGoals}
        >
            {children}
        </WarzoneArcGoalsProvider>
    )
}
